import { ClickAwayListener } from '@mui/material';
import React, { useState } from 'react';

export const Dropdown_container = ({ name, className, isCollectionPage, children }) => {
  const [dropdownShow, setDropdownShow] = useState(false);

  return (
    <ClickAwayListener
      onClickAway={() => {
        setDropdownShow(false);
      }}
      mouseEvent='onMouseDown'
      touchEvent='onTouchStart'
    >
      <div
        className={
          className + ' dropdown relative my-2 min-w-[50%] cursor-pointer md:my-1 md:min-w-[130px]'
        }
      >
        <button
          className='dropdown-toggle text-black inline-flex h-[42px] w-full items-center justify-between rounded-lg border border-jacarta-100 bg-white py-2 px-3 text-sm dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white'
          onClick={() => setDropdownShow(!dropdownShow)}
        >
          <span className='font-display'>{name}</span>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            width='24'
            height='24'
            className='h-4 w-4 fill-jacarta-500 dark:fill-white'
          >
            <path fill='none' d='M0 0h24v24H0z' />
            <path d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z' />
          </svg>
        </button>

        <div
          className={
            dropdownShow && isCollectionPage
              ? 'rounded-xl show absolute top-full left-0 z-10 w-[13rem] whitespace-nowrap bg-white py-4 px-2 text-left shadow-xl dark:border dark:border-jacarta-600 dark:bg-jacarta-800 md:max-w-sm'
              : dropdownShow
              ? 'rounded-xl show absolute top-full right-0 z-10 w-[13rem] whitespace-nowrap bg-white py-4 px-2 text-left shadow-xl dark:border dark:border-jacarta-600 dark:bg-jacarta-800 md:max-w-sm'
              : 'rounded-xl absolute top-full right-0 z-10 hidden w-[13rem] whitespace-nowrap bg-white py-4 px-2 text-left shadow-xl dark:bg-jacarta-800 md:max-w-sm'
          }
        >
          {children}
        </div>
      </div>
    </ClickAwayListener>
  );
};
