import axios from '../lib/axios';
import { constants } from '../helpers/constants';
import useAdaptor from '../lib/swr';
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import { useContext } from 'react';
import { DataRefreshContext } from '../components/refreshContext';

export function useGetWatchlistSingle(userAddress, collectionAddress, options) {
  const { refreshDate } = useContext(DataRefreshContext);
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    if (!collectionAddress || !userAddress) return setEnabled(false);
    setEnabled(ethers.utils.isAddress(collectionAddress) && ethers.utils.isAddress(userAddress));
  }, [collectionAddress, userAddress]);

  const config = {
    url: `${constants.api.url_new}/watchlist/single`,
    method: constants.httpMethods.get,
    params: {
      userAddress,
      collectionAddress,
      refreshDate,
    },
  };

  const fetcher = () =>
    axios(config).then((response) => {
      return response.data.data;
    });

  const isReady = enabled && collectionAddress && userAddress;
  return useAdaptor(isReady ? axios.getUri(config) : null, fetcher);
}

export function useGetWatchlist(userAddress, isReady = false, options) {
  const [enabled, setEnabled] = useState(false);
  const { refreshDate } = useContext(DataRefreshContext);

  useEffect(() => {
    setEnabled(ethers.utils.isAddress(userAddress));
  }, [userAddress]);

  const config = {
    // url: `${constants.api.url}/watchlist`,
    url: `${constants.api.url_new}/watchlist`,
    method: constants.httpMethods.get,
    params: {
      userAddress,
      options: {
        sortBy: options?.sortBy || 'updatedAt:desc',
        limit: options?.limit || 100,
        page: options?.page || 1,
        refreshDate,
      },
    },
  };
  const fetcher = () =>
    axios(config).then((response) => {
      return response.data.data;
    });

  return useAdaptor(isReady && enabled ? axios.getUri(config) : null, fetcher);
}
