import axios from '../lib/axios';
import { constants } from '../helpers/constants';

export async function createWatchlist(data, signature, chainId) {
  const config = {
    // url: `${constants.api.url}/watchlist/create`,
    url: `${constants.api.url_new}/watchlist/create`,
    headers: {
      //'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: constants.httpMethods.post,
    data: { ...JSON.parse(data), signature, chainId },
  };

  const res = (await axios(config)).data.data;

  return res;
}

export async function deleteWatchlist(data, signature, chainId) {
  const config = {
    // url: `${constants.api.url}/watchlist/delete`,
    url: `${constants.api.url_new}/watchlist/delete`,
    headers: {
      //'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: constants.httpMethods.post,
    data: { ...JSON.parse(data), signature, chainId },
  };

  const res = (await axios(config)).data.data;

  return res;
}
