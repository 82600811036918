import { Tooltip } from '@mui/material';
import { BsBookmarkHeart, BsBookmarkHeartFill } from 'react-icons/bs';
import { useAccount, useNetwork, useSignTypedData } from 'wagmi';
import { useGetWatchlistSingle } from '../../hooks/useWatchlist';
import { getAddress } from 'ethers/lib/utils';
import { createWatchlist, deleteWatchlist } from '../../services/watchlist';
import { useContext } from 'react';
import { DataRefreshContext } from '../refreshContext';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { dynamicDomain, types, unWatchMessage, watchMessage } from '../../lib/sign';
import { errorToast } from '../toast';

export const WatchListButton = ({ collectionAddress, placement }) => {
  const { address, isConnected } = useAccount();
  const isWatchlisted = useGetWatchlistSingle(address, collectionAddress).data;
  const { refreshHooks } = useContext(DataRefreshContext);
  const { chain } = useNetwork();

  const { openConnectModal } = useConnectModal();

  const { signTypedDataAsync } = useSignTypedData({
    domain: dynamicDomain(chain?.hexId),
    types: types,
    primaryType: 'Watch',
  });

  const handleLikes = async () => {
    if (!isConnected) {
      return openConnectModal();
    }

    try {
      const inputs = JSON.stringify({
        collectionAddress: collectionAddress,
        userAddress: getAddress(address),
      });

      if (!isWatchlisted) {
        const signature = await signTypedDataAsync({
          message: { Watch: watchMessage },
        });

        await createWatchlist(inputs, signature, chain?.hexId);
      } else {
        const signature = await signTypedDataAsync({
          message: { Watch: unWatchMessage },
        });
        await deleteWatchlist(inputs, signature, chain?.hexId);
      }
      refreshHooks();
    } catch (err) {
      let error = err;
      if (typeof error === 'object') error = JSON.parse(JSON.stringify(err));
      errorToast(err?.shortMessage ?? err?.message ?? 'err: ' + err);
    }
  };
  return (
    <Tooltip
      title={isWatchlisted ? `Remove From WatchList` : `Add To Watchlist`}
      placement={placement ? placement : undefined}
    >
      <div
        className={`inline-flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border-2 border-white ${
          isWatchlisted ? 'bg-green' : 'bg-white'
        } dark:border-jacarta-600`}
        onClick={handleLikes}
        data-testid='watchList'
      >
        {isWatchlisted ? (
          // <Icon name={'icon-heart-fill'} fillCurrent />
          <BsBookmarkHeartFill className='icon h-3 w-3 fill-white' />
        ) : (
          // <Icon name={'icon-hert-fill'} fillCurrent />
          <BsBookmarkHeart className='icon h-3 w-3 fill-[#000]' />
        )}
      </div>
    </Tooltip>
  );
};

const CollectionBadges = ({
  isVerified,
  isDNFT,
  vertical,
  reverse,
  placement,
  collectionAddress,
  watchListDisabled,
}) => {
  return (
    <div
      className={
        'flex items-center' +
        (vertical ? ' flex-col' : '') +
        (reverse ? (vertical ? ' flex-col-reverse' : ' flex-row-reverse') : '')
      }
    >
      {isVerified && (
        <div className='inline-flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-green dark:border-jacarta-600'>
          <Tooltip title={`Verified Collection`} placement={placement ? placement : undefined}>
            <svg className='icon h-[.875rem] w-[.875rem] fill-white'>
              <use xlinkHref='/icons.svg#icon-right-sign' />
            </svg>
          </Tooltip>
        </div>
      )}
      {isDNFT && (
        <div className='inline-flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-blue dark:border-jacarta-600'>
          <Tooltip title={`Dynamic NFT`} placement={placement ? placement : undefined}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
            >
              <circle cx='12' cy='12' r='12' fill='#8473FF' />
              <path
                d='M2.90625 16.1484V10.0547C2.92151 9.32031 3.23442 8.58594 3.84497 7.85156C4.60815 6.96094 5.5545 6.50781 6.68402 6.49219H7.43957V9.51562H6.68402C6.24137 9.5625 5.97425 9.78125 5.88267 10.1719V16.1016C5.88267 16.2734 5.98952 16.4375 6.20321 16.5938C6.35585 16.6875 6.53138 16.7344 6.72981 16.7344H7.43957V19.7812H6.72981C5.34081 19.7812 4.25709 19.1484 3.47864 17.8828C3.09705 17.2578 2.90625 16.6797 2.90625 16.1484ZM7.79183 19.7812V3H10.6938L10.6709 19.7812H7.79183Z'
                fill='#14173D'
              />
              <path
                d='M19.6876 19.7813L13.5506 19.7813L13.5506 19.6773L16.2199 17.9485L19.6876 17.9485L19.6876 19.7813ZM12.1036 19.7813L10.9561 19.7812L10.9561 18.2865L17.592 13.9971L19.6876 13.9971L19.6876 14.868L12.1036 19.7813ZM13.4757 15.8298L10.9561 15.8298L10.9561 13.9971L16.1451 13.9971L16.1451 14.1011L13.4757 15.8298Z'
                fill='white'
              />
              <path
                d='M19.6751 13.6456L10.9561 13.6456L10.9561 8.91431L12.7024 8.91431L12.7024 11.8129L14.4736 11.8129L14.4736 10.1361L16.2324 10.1361L16.2324 11.8129L19.6751 11.8129L19.6751 13.6456Z'
                fill='white'
              />
              <path
                d='M12.6774 8.62817L10.9561 8.62817L10.9561 3L12.6774 3L12.6774 4.89772L19.6876 4.89772L19.6876 6.73045L12.6774 6.73045L12.6774 8.62817Z'
                fill='white'
              />
            </svg>
          </Tooltip>
        </div>
      )}
      {!watchListDisabled && (
        <WatchListButton collectionAddress={collectionAddress} placement={placement} />
      )}
    </div>
  );
};

export default CollectionBadges;
